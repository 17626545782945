var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _c("strong", [_vm._v(" Bootstrap Jumbotron ")]),
                      _c("div", { staticClass: "card-header-actions" }, [
                        _c(
                          "a",
                          {
                            staticClass: "card-header-action",
                            attrs: {
                              href:
                                "https://coreui.io/vue/docs/components/jumbotron",
                              rel: "noreferrer noopener",
                              target: "_blank"
                            }
                          },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("docs")
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CJumbotron",
                        [
                          _c("h1", { staticClass: "display-3" }, [
                            _vm._v("Bootstrap 4")
                          ]),
                          _c("p", { staticClass: "lead" }, [
                            _vm._v("Bootstrap 4 Components for Vue.js 2.6+")
                          ]),
                          _c("p", [
                            _vm._v("For more information visit website")
                          ]),
                          _c(
                            "CButton",
                            { attrs: { color: "primary", href: "#" } },
                            [_vm._v("More Info")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(" Jumbotron ")]),
                      _c("small", [_vm._v("with slots")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CJumbotron",
                        { attrs: { header: "Bootstrap 4", lead: "" } },
                        [
                          _c("h1", { staticClass: "display-3" }, [
                            _vm._v("Bootstrap 4")
                          ]),
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              " This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information. "
                            )
                          ]),
                          _c("hr", { staticClass: "my-4" }),
                          _c("p", [
                            _vm._v(
                              " It uses utility classes for typography and spacing to space content out within the larger container. "
                            )
                          ]),
                          _c(
                            "CButton",
                            { attrs: { color: "primary", href: "#" } },
                            [_vm._v("Do Something")]
                          ),
                          _c(
                            "CButton",
                            { attrs: { color: "success", href: "#" } },
                            [_vm._v("Do Something Else")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(" Jumbotron ")]),
                      _c("small", [_vm._v("colors")])
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CJumbotron",
                        {
                          attrs: {
                            color: "info",
                            "text-color": "white",
                            "border-color": "dark"
                          }
                        },
                        [
                          _c("h1", { staticClass: "display-3" }, [
                            _vm._v("Bootstrap 4")
                          ]),
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(
                              " This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information. "
                            )
                          ]),
                          _c("hr", { staticClass: "my-4" }),
                          _c("p", [
                            _vm._v(
                              " It uses utility classes for typography and spacing to space content out within the larger container. "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }